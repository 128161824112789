import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Translation, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import axios from "axios";

function PinForm() {
  const [pin, setPin] = useState("");
  const [toBenefitCard, setToBenefitCard] = useState(1);
  const [card, setCard] = useState(null);

  const { register, handleSubmit, errors, setError } = useForm();
  const { t } = useTranslation();

  const handlePinChange = (event) => {
    setPin(event.target.value);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();

    axios
      .post("/api/benefitcard", {
        pin: pin,
      })
      .then(function (response) {
        setCard(response.data.benefitCard);
        setToBenefitCard(true);
      })
      .catch(function (error) {
        if (isNaN(pin) || pin.length !== 10) {
          setError("pin", "notMatch", t("pinform.pin.required"));
        } else if (error?.response?.status === 404) {
          setError("pin", "notMatch", t("pinform.pin.404"));
        } else {
          console.log(error);
          setError("pin", "notMatch", t("pinform.pin.error"));
        }
      });
  };

  if (toBenefitCard === true) {
    return (
      <Redirect
        to={{
          pathname: "/BenefitCard",
          state: { card: card },
        }}
      />
    );
  }

  return (
    <Translation>
      {(t) => (
        <div className="form-row justify-content-center">
          <div className="col-md-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <input
                  name="pin"
                  className="form-control"
                  onChange={handlePinChange}
                  placeholder={t("pinform.pin.placeholder")}
                  ref={register({ required: true })}
                />
                {errors.pin && (
                  <p className="text-danger mt-1">{errors.pin.message}</p>
                )}
              </div>

              <input
                type="submit"
                className="btn btn-primary"
                value={t("pinform.sumbit")}
              />
            </form>
          </div>
        </div>
      )}
    </Translation>
  );
}

export default PinForm;
