import React, { useState } from "react";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../AuthProvider";
import { useAlert } from "react-alert";
import ManageMembers from "./ManageMembers";
import axios from "axios";
import { Spinner } from "reactstrap";
import "./ImportMembers.css";

function ImportMembers() {
  const [empFile, setEmpFile] = useState();
  const [removeEmployees, setRemoveEmployees] = useState(false);
  const [updateEmployees, setUpdateEmployees] = useState(false);
  const [updateCompany, setUpdateCompany] = useState(null);
  const [added, setAdded] = useState(null);
  const [removed, setRemoved] = useState(null);
  const [alreadyExists, setAlreadyExists] = useState(null);
  const [updated, setUpdated] = useState(null);
  const [importingEmployees, setImportingEmployees] = useState(false);

  const alert = useAlert();
  const companies = require("../../assets/Files/Companies.json");

  const handleEmpFileChange = (event) => {
    setEmpFile(event.target.files[0]);
  };

  const handleUpdateEmployees = (event) => {
    setUpdateEmployees(!updateEmployees);
  };

  const handleRemoveEmployees = (event) => {
    setRemoveEmployees(!removeEmployees);
  };
  const handleUpdateCompany = (event) => {
    setUpdateCompany(event.target.value);
  };

  const showSuccess = (message) => {
    alert.success(message, { timeout: 60000 });
  };

  const showError = (message) => {
    alert.error(message, { timeout: 60000 });
  };

  const handleImportEmployeesSubmit = async (event) => {
    event.preventDefault();
    setImportingEmployees(true);

    const token = await authProvider.getApiAccessToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
        "Content-Type": "multipart/form-data",
      },
    };

    var data = new FormData();
    data.append("file", empFile);

    if (updateCompany === null || updateCompany === "") {
      showError("Please select a company");
      setImportingEmployees(false);
    } else {
      axios
        .post(
          `/api/admin/import/employees?removeEmployees=${removeEmployees}&updateEmployees=${updateEmployees}&company=${updateCompany}`,
          data,
          config
        )
        .then(function (response) {
          setImportingEmployees(false);
          if (response.data.success) {
            showSuccess(response.data.message);
            setAdded(response.data.added);
            setAlreadyExists(response.data.alreadyExists);
            setRemoved(response.data.removed);
            setUpdated(response.data.updated);
          } else {
            showError(response.data.message);
          }
        })
        .catch(function (error) {
          setImportingEmployees(false);
          console.log(error);
          showError(error.message);
        });
    }
  };

  return (
    <AzureAD provider={authProvider}>
      <ManageMembers />

      <div className="card">
        <div className="card-header">Import employees</div>
        <div className="card-body">
          <div className="mb-4">
            <form onSubmit={handleImportEmployeesSubmit}>
              <div className="form-group">
                <label htmlFor="company">
                  {
                    "Select targeted company - overrides the company in the import file"
                  }
                </label>
                <select
                  id="companies"
                  name="company"
                  className="form-control"
                  onChange={handleUpdateCompany}
                >
                  <option value=""></option>
                  {Object.entries(companies).map((entry, _) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <option value={value} key={key}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <label className="mr-2">Select an Excel file to import</label>
                <input
                  name="file"
                  type="file"
                  className="form-control-file"
                  onChange={handleEmpFileChange}
                />
              </div>
              <div className="form-check">
                <input
                  id="update"
                  name="update"
                  type="checkbox"
                  className="form-check-input"
                  onChange={handleUpdateEmployees}
                />
                <label htmlFor="update" className="form-check-label">
                  Update existing employees with information from import file
                </label>
              </div>
              <div className="form-check">
                <input
                  id="remove"
                  name="remove"
                  type="checkbox"
                  className="form-check-input"
                  onChange={handleRemoveEmployees}
                />
                <label htmlFor="remove" className="form-check-label">
                  Remove existing employees that don't exist in the import file
                </label>
              </div>
              <button type="submit" className="btn btn-primary mt-2">
                {importingEmployees && <Spinner size="sm" className="mr-2" />}
                Import employees
              </button>
              <div></div>
            </form>
          </div>
        </div>
      </div>

      {added !== null && (
        <div className="mb-4">
          <h4>{added.length} added</h4>
          <table className="members">
            <thead>
              <tr>
                <th>Pin</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              {added.map(function (member) {
                return (
                  <tr key={member.pin}>
                    <td>{member.pin}</td>
                    <td>{member.firstName}</td>
                    <td>{member.lastName}</td>
                    <td>{member.email}</td>
                    <td>{member.company}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {updated !== null && (
        <div className="mb-4">
          <h4>{updated.length} updated</h4>
          <table className="members">
            <thead>
              <tr>
                <th>Pin</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              {updated.map(function (member) {
                return (
                  <tr key={member.pin}>
                    <td>{member.pin}</td>
                    <td>{member.firstName}</td>
                    <td>{member.lastName}</td>
                    <td>{member.email}</td>
                    <td>{member.company}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {removed !== null && (
        <div className="mb-4">
          <h4>{removed.length} removed</h4>
          <table className="members">
            <thead>
              <tr>
                <th>Pin</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              {removed.map(function (member) {
                return (
                  <tr key={member.pin}>
                    <td>{member.pin}</td>
                    <td>{member.firstName}</td>
                    <td>{member.lastName}</td>
                    <td>{member.email}</td>
                    <td>{member.company}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {alreadyExists !== null && (
        <div className="mb-4">
          <h4>{alreadyExists.length} skipped because they already exists</h4>
          <table className="members">
            <thead>
              <tr>
                <th>Pin</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              {alreadyExists.map(function (member) {
                return (
                  <tr key={member.pin}>
                    <td>{member.pin}</td>
                    <td>{member.firstName}</td>
                    <td>{member.lastName}</td>
                    <td>{member.email}</td>
                    <td>{member.company}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </AzureAD>
  );
}

export default ImportMembers;
