import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../AuthProvider";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import ManageMembers from "./ManageMembers";

function EditMember(props) {
  const [id, setId] = useState();
  const [intialValues, setIntialValues] = useState();
  const [saving, setSaving] = useState();
  const [deleting, setDeleting] = useState();

  const { register, handleSubmit, errors, reset } = useForm();
  const alert = useAlert();
  const history = useHistory();

  const showSuccess = (message) => {
    alert.success(message);
  };
  const showError = (message) => {
    if (message == "Request failed with status code 401") {
      alert.error("You don't have access to this company");
    } else {
      alert.error(message);
    }
  };

  const companies = require("../../assets/Files/Companies.json");

  useEffect(() => {
    getMember(props.match.params.id);
  }, [id]);

  const getMember = async () => {
    const token = await authProvider.getApiAccessToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
      },
    };

    axios
      .get(`/api/admin/member/${props.match.params.id}`, config)
      .then(function (response) {
        var data = response.data;

        setIntialValues(data);
      })
      .catch(function (error) {
        if (error?.response.status === 404) {
          showError("Cannot edit member. Member was not found!");
          history.push("/admin/SearchMembers");
        } else {
          console.log(error);
          showError(error);
        }
      });
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setSaving(true);

    const token = await authProvider.getApiAccessToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
      },
    };

    axios
      .put(`/api/admin/member/${intialValues.id}`, data, config)
      .then(function (response) {
        showSuccess(`Changes has been saved`);
        setSaving(false);
      })
      .catch(function (error) {
        setSaving(false);
        console.log(error);
        showError(error.message);
      });
  };

  const handleDelete = async () => {
    setDeleting(true);

    const token = await authProvider.getApiAccessToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
      },
    };

    axios
      .delete(`/api/admin/member/${intialValues.id}`, config)
      .then(function (response) {
        setDeleting(false);
        showSuccess(`Member has been removed`);
        history.push("/admin/SearchMembers");
      })
      .catch(function (error) {
        setDeleting(false);
        console.log(error);
        showError(error.message);
      });
  };

  return (
    <AzureAD provider={authProvider}>
      <ManageMembers />

      <div className="card">
        <div className="card-header">Edit member</div>
        <div className="card-body">
          {!intialValues?.id && (
            <div>
              <Spinner color="dark" />
            </div>
          )}

          {intialValues?.id && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="pin">Personal number</label>
                <input
                  id="pin"
                  name="pin"
                  className="form-control"
                  placeholder="Enter pin"
                  defaultValue={intialValues.pin}
                  ref={register({
                    required: true,
                    pattern: /^[0-9\b]+$/i,
                    minLength: 10,
                    maxLength: 10,
                  })}
                />
                {errors.pin && errors.pin.type === "required" && (
                  <p className="text-danger mt-1">This field is required</p>
                )}
                {errors.pin && errors.pin.type === "pattern" && (
                  <p className="text-danger mt-1">
                    The field can only contain digits
                  </p>
                )}
                {errors.pin && errors.pin.type === "minLength" && (
                  <p className="text-danger mt-1">
                    This field need to be 10 digits
                  </p>
                )}
                {errors.pin && errors.pin.type === "maxLength" && (
                  <p className="text-danger mt-1">
                    This field need to be 10 digits
                  </p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="firstName">First name</label>
                <input
                  id="firstName"
                  name="firstName"
                  className="form-control"
                  placeholder="Enter first name"
                  defaultValue={intialValues.firstName}
                  ref={register({ required: true })}
                />
                {errors.firstName && (
                  <p className="text-danger mt-1">This field is required</p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="lastName">Last name</label>
                <input
                  id="lastName"
                  name="lastName"
                  className="form-control"
                  placeholder="Enter last name"
                  defaultValue={intialValues.lastName}
                  ref={register({ required: true })}
                />
                {errors.lastName && (
                  <p className="text-danger mt-1">This field is required</p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  defaultValue={intialValues.email}
                  ref={register({ required: false })}
                />
                {errors.email && (
                  <p className="text-danger mt-1">This field is required</p>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="company">Company</label>
                <select
                  id="company"
                  name="company"
                  className="form-control"
                  defaultValue={intialValues.company}
                  ref={register({ required: true })}
                >
                  {Object.entries(companies).map((entry, _) => {
                    let key = entry[0];
                    let value = entry[1];
                    return (
                      <option value={value} key={key}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="row">
                <div className="col-6 col-xs-12">
                  <button type="submit" className="btn btn-primary">
                    {saving && <Spinner size="sm" className="mr-2" />}
                    Save
                  </button>
                </div>
                <div className="col-6 col-xs-12">
                  <button
                    type="button"
                    className="btn btn-danger float-right"
                    onClick={handleDelete}
                  >
                    {deleting && <Spinner size="sm" className="mr-2" />}
                    Delete
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </AzureAD>
  );
}

export default EditMember;
