import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../AuthProvider";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import ManageMembers from "./ManageMembers";
import $ from "jquery";
import "./SearchMembers.css";
import { Spinner } from "reactstrap";
import { Translation, useTranslation } from "react-i18next";

function SearchMembers() {
  const [loading, setLoading] = useState();
  const [searchResult, setSearchResult] = useState();

  const { handleSubmit } = useForm();
  const alert = useAlert();
  const history = useHistory();

  const showError = (message) => {
    if (message == "Request failed with status code 401") {
      alert.error("You don't have access to this company");
    } else {
      alert.error(message);
    }
  };
  const companies = require("../../assets/Files/Companies.json");
  const { t } = useTranslation();

  const editMember = (event) => {
    var id = $(event.currentTarget).data("id");
    history.push(`/admin/editmember/${id}`);
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();

    setLoading(true);

    const token = await authProvider.getApiAccessToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
      },
    };

    const pin = event.target.pin.value;
    const email = event.target.email.value;
    const firstName = event.target.firstName.value;
    const lastName = event.target.lastName.value;
    const company = event.target.company.value;

    if (company === null || company === "") {
      showError("Please select a company");
      setLoading(false);
      return;
    }

    axios
      .get(
        `/api/admin/member?pin=${pin}&email=${email}&firstName=${firstName}&lastName=${lastName}&company=${company}`,
        config
      )
      .then(function (response) {
        setLoading(false);
        setSearchResult(response.data);
      })
      .catch(function (error) {
        if (error.response === null ? false : error.response.status === 404) {
          showError("Not found!");
        } else {
          console.log(error);
          showError(error.message);
        }
        setLoading(false);
      });
  };

  return (
    <Translation>
      {(t) => (
        <AzureAD provider={authProvider}>
          <ManageMembers />

          <div className="card">
            <div className="card-header">
              {t("manager.navbar.search.title")}
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-sm-12 col-md-6 form-group">
                    <label htmlFor="pin">{t("personalnumber")}</label>
                    <input
                      name="pin"
                      className="form-control"
                      placeholder={t("personalnumber")}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 form-group">
                    <label htmlFor="email">{t("email")}</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder={t("email")}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 form-group">
                    <label htmlFor="name">{t("firstname")}</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder={t("firstname")}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 form-group">
                    <label htmlFor="name">{t("lastname")}</label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control"
                      placeholder={t("lastname")}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 form-group">
                    <label htmlFor="company">{t("company")}</label>
                    <select
                      id="companies"
                      name="company"
                      className="form-control"
                    >
                      <option value=""></option>
                      {Object.entries(companies).map((entry, _) => {
                        let key = entry[0];
                        let value = entry[1];
                        return (
                          <option value={value} key={key}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      value="Search"
                    >
                      {loading && <Spinner size="sm" className="mr-2" />}
                      {t("manager.navbar.search")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div>
            {searchResult && (
              <div className="my-3">
                <table className="searchResult">
                  <thead>
                    <tr>
                      <th>{t("personalnumber")}</th>
                      <th>{t("firstname")}</th>
                      <th>{t("lastname")}</th>
                      <th>{t("email")}</th>
                      <th>{t("company")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResult.map(function (member) {
                      return (
                        <tr
                          key={member.id}
                          data-id={member.id}
                          onClick={editMember}
                        >
                          <td>{member.pin}</td>
                          <td>{member.firstName}</td>
                          <td>{member.lastName}</td>
                          <td>{member.email}</td>
                          <td>{member.company}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </AzureAD>
      )}
    </Translation>
  );
}

export default SearchMembers;
