import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import BenefitCard from "./components/BenefitCard";
import NotFound from "./components/NotFound";
import SearchMembers from "./components/admin/SearchMembers";
import ImportMembers from "./components/admin/ImportMembers";
import ExportMembers from "./components/admin/ExportMembers";
import AddMember from "./components/admin/AddMember";
import EditMember from "./components/admin/EditMember";
import Login from "./components/Login";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "./components/AuthProvider";
import "./styles/custom.css";
import "./custom.css";

function App() {
  const LoggedOut = () => {
    return <p>You have been logged out</p>;
  };

  return (
    <AzureAD provider={authProvider}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        return (
          <BrowserRouter>
            <Layout>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/BenefitCard" component={BenefitCard} />
                <Route path="/Login" component={Login} />
                <Route path="/Logout" component={Login} />
                <Route path="/admin/SearchMembers" component={SearchMembers} />
                <Route path="/admin/AddMember" component={AddMember} />
                <Route path="/admin/EditMember/:id" component={EditMember} />
                <Route path="/admin/ImportMembers" component={ImportMembers} />
                <Route path="/admin/ExportMembers" component={ExportMembers} />
                <Route path="/LoggedOut" component={LoggedOut} />
                <Route component={NotFound} />
              </Switch>
            </Layout>
          </BrowserRouter>
        );
      }}
    </AzureAD>
  );
}

export default App;
