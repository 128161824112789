import React from "react";
import { Translation } from "react-i18next";

function NotFound() {
  return (
    <Translation>
      {(t) => (
        <div>
          <h1>{t("404.title")}</h1>
          <p>{t("404.message")}</p>
        </div>
      )}
    </Translation>
  );
}

export default NotFound;
