import {
  MsalAuthProvider,
  LoginType,
  AccessTokenResponse,
  AuthenticationActionCreators,
} from "react-aad-msal";
import { Logger, LogLevel } from "msal";

const config = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECTURI,
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUTURI,
    navigateToLoginRequestUrl: false,
    validateAuthority: true,
  },
  //Enable logging of MSAL events for easier troubleshooting.
  //This should be disabled in production builds.
  system: {
    logger: new Logger(
      (logLevel, message, containsPii) => {
        console.log("[MSAL]", message);
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false,
      }
    ),
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const authenticationParameters = {
  scopes: ["openid", process.env.REACT_APP_SCOPE],
};

const options = {
  loginType: LoginType.Redirect,
};

class AuthProvider extends MsalAuthProvider {
  getApiAccessToken = async () => {
    try {
      const response = await this.acquireTokenSilent(authenticationParameters);

      this.handleAcquireTokenSuccess(response);
      this.setAuthenticationState("Authenticated");

      return new AccessTokenResponse(response);
    } catch (error) {
      this.dispatchAction(
        AuthenticationActionCreators.acquireAccessTokenError(error)
      );
      const response = await this.loginToRefreshToken(
        error,
        authenticationParameters
      );
      return new AccessTokenResponse(response);
    }
  };
}

export const authProvider = new AuthProvider(
  config,
  authenticationParameters,
  options
);
