import React from "react";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./AuthProvider";

function Login() {
  return (
    <AzureAD provider={authProvider} forceLogin={true}>
      {({ login, logout, authenticationState, error, accountInfo }) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            return (
              <React.Fragment>
                <p>
                  <span>Welcome, {accountInfo.account.name}!</span>
                </p>
                <button onClick={logout}>Logout</button>
              </React.Fragment>
            );
          case AuthenticationState.Unauthenticated:
            return (
              <div>
                {error && (
                  <p>
                    <span>
                      An error occured during authentication, please try again!
                    </span>
                  </p>
                )}
                <p>
                  <span>
                    Please verify that you belong to the correct AD group to
                    access this application
                  </span>
                  <button onClick={login}>Login</button>
                </p>
              </div>
            );
          case AuthenticationState.InProgress:
            return <p>Authenticating...</p>;
          default:
        }
      }}
    </AzureAD>
  );
}

export default Login;
