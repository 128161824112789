import React from "react";
import { Link } from "react-router-dom";
import { Translation, useTranslation } from "react-i18next";

function ManageMembers() {
  const { t } = useTranslation();

  return (
    <Translation>
      {(t) => (
        <div className="mb-2 mb-xs-2">
          <Link to="/admin/SearchMembers">
            <button className="mr-2 mb-xs-2 mb-2">
              {t("manager.navbar.search")}
            </button>
          </Link>
          <Link to="/admin/AddMember">
            <button className="mr-2 mb-xs-2 mb-2">
              {t("manager.navbar.add")}
            </button>
          </Link>
          <Link to="/admin/ExportMembers">
            <button className="mr-2 mb-xs-2 mb-2">
              {t("manager.navbar.export")}
            </button>
          </Link>
          <Link to="/admin/ImportMembers">
            <button className="mr-2 mb-xs-2 mb-2">
              {t("manager.navbar.import")}
            </button>
          </Link>
        </div>
      )}
    </Translation>
  );
}

export default ManageMembers;
