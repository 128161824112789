import React from "react";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import NavMenu from "./NavMenu";

function Layout(props) {
  return (
    <div>
      <NavMenu />
      <Container>{props.children}</Container>
    </div>
  );
}

export default withRouter(Layout);
