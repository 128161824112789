import React, { useState } from "react";
import { Spinner } from "reactstrap";
import axios from "axios";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../AuthProvider";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import ManageMembers from "./ManageMembers";

function AddMember(props) {
  const [saving, setSaving] = useState();

  const { register, handleSubmit, errors, reset } = useForm();
  const alert = useAlert();

  const showSuccess = (message) => {
    alert.success(message);
  };

  const showError = (message) => {
    if (message == "Request failed with status code 401") {
      alert.error("You don't have access to this company");
    } else {
      alert.error(message);
    }
  };

  const companies = require("../../assets/Files/Companies.json");

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setSaving(true);

    const token = await authProvider.getApiAccessToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token.accessToken,
      },
    };

    axios
      .post("/api/admin/member", data, config)
      .then(function (response) {
        showSuccess(
          `${data.pin} ${data.firstName} ${data.lastName} has been added`
        );
        reset();
        setSaving(false);
      })
      .catch(function (error) {
        setSaving(false);
        if (error.response === null ? false : error.response.status === 409) {
          showError(error.response.data);
        } else {
          console.log(error);
          showError(error.message);
        }
      });
  };

  return (
    <AzureAD provider={authProvider}>
      <ManageMembers />

      <div className="card">
        <div className="card-header">Add new member</div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="pin">Personal number</label>
              <input
                id="pin"
                name="pin"
                className="form-control"
                placeholder="Enter pin"
                ref={register({
                  required: true,
                  pattern: /^[0-9\b]+$/i,
                  minLength: 10,
                  maxLength: 10,
                })}
              />
              {errors.pin && errors.pin.type === "required" && (
                <p className="text-danger mt-1">This field is required</p>
              )}
              {errors.pin && errors.pin.type === "pattern" && (
                <p className="text-danger mt-1">
                  The field can only contain digits
                </p>
              )}
              {errors.pin && errors.pin.type === "minLength" && (
                <p className="text-danger mt-1">
                  This field need to be 10 digits
                </p>
              )}
              {errors.pin && errors.pin.type === "maxLength" && (
                <p className="text-danger mt-1">
                  This field need to be 10 digits
                </p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="firstName">First name</label>
              <input
                id="firstName"
                name="firstName"
                className="form-control"
                placeholder="Enter first name"
                ref={register({ required: true })}
              />
              {errors.firstName && (
                <p className="text-danger mt-1">This field is required</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last name</label>
              <input
                id="lastName"
                name="lastName"
                className="form-control"
                placeholder="Enter last name"
                ref={register({ required: true })}
              />
              {errors.lastName && (
                <p className="text-danger mt-1">This field is required</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                className="form-control"
                placeholder="Email"
                ref={register({ required: false })}
              />
            </div>

            <div className="form-group">
              <label htmlFor="company">Company</label>
              <select
                id="company"
                name="company"
                className="form-control"
                ref={register({ required: true })}
              >
                <option value=""></option>
                {Object.entries(companies).map((entry, _) => {
                  let key = entry[0];
                  let value = entry[1];
                  return (
                    <option value={value} key={key}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>

            <button type="submit" className="btn btn-primary">
              {saving && <Spinner size="sm" className="mr-2" />}
              Add Employee
            </button>
          </form>
        </div>
      </div>
    </AzureAD>
  );
}

export default AddMember;
