import React from "react";
import "./BenefitCard.css";

function BenefitCard(props) {
  return (
    <div className="mt-3">
      <React.Fragment>
        <div className="mt-3">
          <div className="benefit-card card shadow">
            <div className="card-body py-1 pr-2">
              <div className="row my-4">
                <div className="col-12">
                  <span className="h2">{"Valid PIN"}</span>
                </div>
                <div className="col-12">
                  <span className="h3">
                    {props.location.state.card.pin}
                  </span>
                </div>
                <div className="col-12">
                  <span className="h2">
                    {props.location.state.card.company}
                  </span>
                </div>
                <div className="col-12">
                  <span className="h5">
                    {"Timestamp: " + props.location.state.card.dateStamp}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}

export default BenefitCard;
