import "./styles/custom.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/shared/alertTemplate/AlertTemplate";
import App from "./App";
//import registerServiceWorker from "./registerServiceWorker";
import { unregister } from './registerServiceWorker';
import i18n from "./i18n";

require("dotenv").config();

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter basename={baseUrl}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <App />
      </AlertProvider>
    </BrowserRouter>
  </I18nextProvider>,
  rootElement
);

// no cache
// registerServiceWorker();
unregister();
