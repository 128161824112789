import React from "react";
import PinForm from "./PinForm";

function Home() {
  return (
    <div>
      <PinForm />
    </div>
  );
}

export default Home;
