import React, { useState } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./AuthProvider";
import "./NavMenu.css";
import logo from "../assets/images/nwg_logo_white.svg";
import $ from "jquery";
import { Translation, useTranslation } from "react-i18next";

function NavMenu(props) {
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();

  function toggleNavbar() {
    setCollapsed(!collapsed);
    if (!collapsed) {
      $(".animated-hamburger").removeClass("open");
    } else {
      $(".animated-hamburger").addClass("open");
    }
  }

  return (
    <Translation>
      {(t) => (
        <header>
          <Navbar className="navbar-expand-sm navbar-toggleable-sm bg-primary border-bottom mb-3">
            <Container>
              <NavbarBrand tag={Link} to="/" className="text-white">
                <img
                  src={logo}
                  alt="Newwave group benefit card"
                  className="logo"
                />
              </NavbarBrand>
              <NavbarToggler onClick={toggleNavbar}>
                <div className="animated-hamburger">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </NavbarToggler>
              <Collapse
                className="d-sm-inline-flex flex-sm-row-reverse"
                isOpen={!collapsed}
                navbar
              >
                <ul className="navbar-nav flex-grow">
                  <AzureAD provider={authProvider}>
                    {({
                      login,
                      logout,
                      authenticationState,
                      error,
                      accountInfo,
                    }) => {
                      switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                          return (
                            <React.Fragment>
                              <NavItem>
                                <NavLink
                                  tag={Link}
                                  className="text-white"
                                  to="/"
                                >
                                  {t("navbar.home")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  tag={Link}
                                  className="text-white"
                                  to="/admin/SearchMembers"
                                >
                                  {t("navbar.managemembers")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  tag={Link}
                                  className="text-white"
                                  to="/Logout"
                                >
                                  {t("navbar.logout")}
                                </NavLink>
                              </NavItem>
                            </React.Fragment>
                          );
                        case AuthenticationState.Unauthenticated:
                        case AuthenticationState.InProgress:
                          return (
                            <React.Fragment>
                              <NavItem>
                                <NavLink
                                  tag={Link}
                                  className="text-white"
                                  to="/"
                                >
                                  {t("navbar.home")}
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  tag={Link}
                                  className="text-white"
                                  to="/Login"
                                >
                                  {t("navbar.login")}
                                </NavLink>
                              </NavItem>
                            </React.Fragment>
                          );
                        default:
                      }
                    }}
                  </AzureAD>
                </ul>
              </Collapse>
            </Container>
          </Navbar>
        </header>
      )}
    </Translation>
  );
}

export default NavMenu;
