import React, { useState } from "react";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../AuthProvider";
import { Spinner } from "reactstrap";
import { useAlert } from "react-alert";
import { saveAs } from "file-saver";
import ManageMembers from "./ManageMembers";

function ExportMembers() {
  const alert = useAlert();
  const [exporting, setExporting] = useState();
  const [updateCompany, setUpdateCompany] = useState(null);

  const showSuccess = (message) => {
    alert.success(message);
  };

  const showError = (message) => {
    if (message == "Cannot read properties of null (reading 'split')") {
      alert.error("You don't have access to this company");
    } else {
      alert.error(message);
    }
  };
  const companies = require("../../assets/Files/Companies.json");

  const handleUpdateCompany = (event) => {
    setUpdateCompany(event.target.value);
  };

  const handleExport = async (event) => {
    event.preventDefault();

    setExporting(true);

    const token = await authProvider.getApiAccessToken();
    //const company = event.target.company.value;
    try {
      const response = await fetch(
        `/api/admin/export?company=${updateCompany}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: `Bearer ${token.accessToken}`,
          }),
        }
      );
      if (updateCompany === null || updateCompany === "") {
        showError("Please select a company");
      } else {
        // Extract filename from header
        const filename = response.headers
          .get("content-disposition")
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();

        const blob = await response.blob();

        // Download the file
        saveAs(blob, filename);

        showSuccess("Members has been exported");
      }
      if (!response.ok) {
        throw new Error(response);
      }
    } catch (error) {
      console.log(error);
      showError(error.message);
    }

    setExporting(false);
  };

  return (
    <AzureAD provider={authProvider}>
      <ManageMembers />

      <div className="card">
        <div className="card-header">Export members to an Excel file</div>
        <div className="card-body">
          <div className="col-sm-12 col-md-6 form-group">
            <label htmlFor="company">{"Select company"}</label>
            <select
              id="companies"
              name="company"
              className="form-control"
              onChange={handleUpdateCompany}
            >
              <option value=""></option>
              {Object.entries(companies).map((entry, _) => {
                let key = entry[0];
                let value = entry[1];
                return (
                  <option value={value} key={key}>
                    {value}
                  </option>
                );
              })}
            </select>

            <button className="btn btn-primary mt-2" onClick={handleExport}>
              {exporting && <Spinner size="sm" className="mr-2" />}
              Export
            </button>
          </div>
        </div>
      </div>
    </AzureAD>
  );
}

export default ExportMembers;
